import { IBarData } from "../features/charts/ChartInterfaces";

export const ShaunFordeExperienceData = {
    backEnd: [
        { valueType: "Years", name: "C#", value: 9, additionalData: "9 years of C# experience, from .net Desktop/Web to Desktop and Windows Services. Predominantly in API development, Microservice implementation and Desktop applications. - all with extensive experience in Object Oriented & Functional Design Patterns. Competencies in EF Core, TPL, CQRS (MediatR) Azure Functions and more." },
        { valueType: "Years", name: "SQL", value: 13, additionalData: "13 Years of experience in using SQL in Enterprise level applications. Both for on premise and cloud databases." },
        { valueType: "Years", name: ".Net Core", value: 5, additionalData: "5 years of experience in .Net Core (more recently .Net 6). Previous to this, extensive experience in Net Framework 4.x" },
        { valueType: "Years", name: "OAuth Integrations", value: 5, additionalData: "Designing and implemented OAuth flows that use both " },
        { valueType: "Years", name: "Azure Cloud Development", value: 6, additionalData: "6 years of designing and deploying Azure resources (App Services, Databases, OAuth Providers, API Gateways, Static Sites and more)- for both large and small businesses." },
        { valueType: "Years", name: "Devops CI/CD", value: 8, additionalData: "8 years desiging and implementing Continuous Integration and Deployment Pipelines, specialising in Azure DevOps technologies. Experience in other build systems also inclides TeamCity." }
    ] as IBarData[],
    frontEnd: [
        { valueType: "Years", name: "Javascript", value: 11, additionalData: "11 Years writing Javascript and using related framework, predominantly Angular/React but also Vue/Aurelia." },
        { valueType: "Years", name: "Typescript", value: 6, additionalData: "6 Years using Typescript in commercial applications, including React/Angular and D3 charts." },
        { valueType: "Years", name: "HTML/CSS", value: 14, additionalData: "12 years in development of HTML layouts and style, from highly stylised web sites to information dense dashboards. Includes CSS Pre-processors such as LESS and SCSSSASS"  },
        { valueType: "Years", name: "UX/UI Design", value: 6, additionalData: "Front End Development Lead in multiple roles, including development of UI Style guides and UX strategy development. Delivered complex requirements from the ground up in Angular and React frameworks."  },
        { valueType: "Years", name: "D3/Data Visualization", value: 14, additionalData: "Lead developer for analytics for multiple organisations. Advanced competency in D3.js chart creation and development of custom pivot table components. Drawing from experience in d3.js and other libraries (AmChart/GoogleMaps) over 12 years of creating dynamic and interactive data visualisations."  }
    ] as IBarData[]
}