type ModelAsset = {
    name: string,
    fileName: string
}

class ModelAssets {

    static Gateway: ModelAsset = {
        name: 'Gateway',
        fileName: 'single ring parented_MODIFIED.glb'
    }

}

export default ModelAssets;

