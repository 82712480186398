class ObjectCacheService {

    static tryGetFromCach = () => {
        if (canBrowserCache()) {

        }
    }

    static getResourceAndCache = async (cacheName: string, resourceUrl:string) => {
        
        if (canBrowserCache()) {

            const cache = await getCache(cacheName);
            
            let cachedData:Response | undefined;
            let isCached = await isRequestCached(cache, resourceUrl);

            if(!isCached){
                // request data via cache add:
                await cache.add(new Request(resourceUrl));
            }
            
            cachedData = await getFromCache(cache, resourceUrl);                
            
            if(cachedData){
                return await cachedData.arrayBuffer();                    
            }
        }        
    }
}

const getFromCache = async (cache:Cache, request: string) => {

    return cache.match(request).then(request => {
        if (request?.status === 200) {
            return request;
        } else {
            return;
        }

    });
}

const isRequestCached = async (cache:Cache, request: string) => {


    return cache.match(request).then(request => {
        if (request?.status === 200) {
            return true;
        } else {
            return false;
        }

    });
}

const canBrowserCache: () => boolean = () => {
    
    const cacheAvailable = 'caches' in window;

    return cacheAvailable;
}

const getCache = async (cacheName: string) => {
    return await caches.open(cacheName);
}

export interface ICacheObject {
    id: string,
    object: any
}

export default ObjectCacheService;