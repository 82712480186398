export const GatewaySceneVariables = {

    cameraStages: [
        {
            stageName: 'stage1',
            cameraX: 7.96,
            cameraY: 0.6876266744315622,
            cameraZ: -4.436637378124506
        },
        {
            stageName: 'stage2',
            cameraX: 3.10,
            cameraY: 0.6876266744315622,
            cameraZ: -4.436637378124506
        },
        {
            stageName: 'stage3',
            cameraX: -1.02,
            cameraY: 0.6876266744315622,
            cameraZ: -4.436637378124506
        }
    ] as ICameraStage[],

}

export interface ICameraStage {
    stageName: string,
    cameraX: number,
    cameraY: number,
    cameraZ: number
}