import React from "react";

import {UUIDContext} from "./UUIDContext";
import uuid from "react-uuid";

export function useUIDGlobal(){
    return React.useContext(UUIDContext);
}

export default function UUIDProvider({ children }: { children: React.ReactNode }){

    let getUUID = () =>{
        return uuid();
    }
    
    var contextValue = {getUUID};
    
    return (<UUIDContext.Provider value={ contextValue }> { children } </UUIDContext.Provider>);
}