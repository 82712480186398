

import ChartDetailsProvider from "../../../contexts/chartDetailsContext/ChartDetailsProvider";
import { useUIDGlobal } from "../../../contexts/UUIDProvider";
import { ShaunFordeExperienceData } from "../../../data/ShaunFordeExperience";
import { IBarData, IBarTheme } from "../ChartInterfaces";
import HorizontalBar from "../HorizontalBar";
import ChartDetails from "./ChartDetailsSection";
import styles from './sharedAccessDivergentChart.module.css';

/* Similar to a divergent bar chart, but there are 2 X-Axis back to back. 
    This allows data with the same Y axis to be shown against 2 different X Axis Values ( eg car/bike over distance or Frontend/Backend over years of experience) */
/* The resultant chart is 2 barcharts back to back inverted, showing xAxis1 towards the left, and xAxis 2 towards the right */
 
const SharedAxisDivergentChart = () => {

   const x1Data = ShaunFordeExperienceData.backEnd;
   const x2Data = ShaunFordeExperienceData.frontEnd;

    const barTheme: IBarTheme = {
        PrimaryAxisColors: ["#0ec900"],
        SecondaryAxisColors: ["#b51313"],
        HighlightColors: ["#9bbb00"]
    }

    /* External Data */
    const uuid = useUIDGlobal();
    
    const chart1UId = uuid.getUUID();
    const chart2UId = uuid.getUUID();

    return (
        <>
            <svg width="0" height="0">
                {insertGlobalSvgDefs()}
            </svg>

            <ChartDetailsProvider>

                <div className={`row`} >

                    <div className="col-sm-12">

                        <div className="row pb-xl-2 pb-sm-2">
                            <span className={styles.chartMainTitle}>Technology Experience (Years)</span>
                            <span className={styles.chartTitleFlirt}>click a bar for more details</span>
                        </div>


                        <div className="row" style={{ position: 'relative' }}>
                            
                            <div className={`col-md-6 col-sm-12 pr-0 ${styles.chartContainer}`}>

                                <span className={[styles.chartSubTitle, styles.chartLeftTitle].join(' ')}>Back End</span>

                                <HorizontalBar
                                    data={x1Data}
                                    direction="Left"
                                    xAxisTitle="Experience (Years)"
                                    chartTitle={null}
                                    theme={barTheme}                                
                                    uuid={chart1UId}
                                />

                            </div>

                            {/* Chart Details for First Chart (only for sm breakpoints) */}

                            <div className="col-sm-12 d-md-none d-lg-none">
                                <div className="col-sm-12">

                                    <div className={styles.chartDetailsSection}>

                                        <div className={`d-flex justify-content-center pt-4`}>
                                            <div className={`col-sm-8`} style={{ position: 'relative' }}>

                                                <ChartDetails chartId={chart1UId} />

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            {/* <div className={`${styles.chartSpacer} d-none d-sm-none d-md-none d-lg-none d-xl-block`}></div> */}
                            
                            <div className={`col-md-6 col-sm-12 pl-0 ${styles.chartContainer}`}>

                                <span className={[styles.chartSubTitle, styles.chartRightTitle].join(' ')}>Front End</span>

                                <HorizontalBar
                                    data={x2Data}
                                    direction="Right"
                                    xAxisTitle="Experience (Years)"
                                    chartTitle={null}
                                    theme={barTheme}                                
                                    uuid={chart2UId}
                                />
                                
                            </div>

                             {/* Chart Details for Second Chart (only for sm breakpoints) */}

                             <div className="col-sm-12 d-md-none d-lg-none">
                                <div className="col-sm-12">

                                    <div className={styles.chartDetailsSection}>

                                        <div className={`d-flex justify-content-center pt-4`}>
                                            <div className={`col-sm-8`} style={{ position: 'relative' }}>

                                                <ChartDetails chartId={chart2UId} />

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="row d-none d-sm-none d-md-none d-lg-none d-xl-block">
                            <div className="col-sm-12">

                                <div className={styles.chartDetailsSection}>

                                    <div className={`d-flex justify-content-center pt-4`}>
                                        <div className={`col-sm-8`} style={{ position: 'relative' }}>

                                        <ChartDetails/>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                
            </ChartDetailsProvider>
        </>
    )
}


/* Utilities */

const insertGlobalSvgDefs = () => {
    return (<defs>      
        <filter id="dropshadow" colorInterpolationFilters="sRGB">
            <feOffset result="offOut" in="SourceAlpha" dx="2" dy="2" />
            <feColorMatrix in="offOut" result="matrixOut" type="matrix"
                values="0 0 0 0 0.5
                        0 0 0 0 1
                        0 0 0 0 1 
                        0 0 0 0.5 0" />
            <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="1" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
    </defs>);
};


export default SharedAxisDivergentChart;