import { IBarData } from "../features/charts/ChartInterfaces";

const EventBus = {
    on(event:any, callback:any){
        document.addEventListener(event, (e) => callback(e.detail));        
    },
    dispatch(event:any, data:IBarChartFocusEvent){
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event:any, callback:any){
        document.removeEventListener(event, callback);
    }
}

export interface IBarChartFocusEvent {
    chartInFocusId: string,
    barData: IBarData | null
}

export default EventBus;