export default function Footer() {
    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="container">

                    <div className="row" >
                        
                        <div className="col-sm-6" style={{textAlign:'left'}}>
                            <span className="light-shade">Copyright {(new Date().getFullYear())} Material Methods Limited</span>                            
                        </div>

                        <div className="col-sm-6" style={{textAlign:'right'}}>
                            <div className="col-sm-12"><span className="light-shade">Material Methods Limited</span></div>
                            <div className="col-sm-12"><span className="light-shade">Company number 14128088</span></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}