import { on } from 'events';
import * as THREE from 'three';
import { FontLoader } from '../../../../assets/threejs/loaders/FontLoader.js';

interface ShapeFromTextResponse {
    meshes: THREE.Mesh<THREE.ShapeGeometry, THREE.MeshBasicMaterial>[],
    objects: THREE.Object3D<THREE.Event>[]
}

const GenerateShapesFromText = async (textToGenerate: string, size:number) => {

    const loader = new FontLoader();

    const onFontLoad = function (font: any) {
    
        let output: ShapeFromTextResponse = {
            meshes: [],
            objects: []
        };

        const color = 0x006699;

        const matDark = new THREE.LineBasicMaterial({
            color: color,
            side: THREE.DoubleSide
        });

        const matLite = new THREE.MeshBasicMaterial({
            color: color,
            transparent: true,
            opacity: 0.4,
            side: THREE.DoubleSide
        });

        const message = textToGenerate;

        const shapes = font.generateShapes(message, size);

        const geometry = new THREE.ShapeGeometry(shapes);

        geometry.computeBoundingBox();

        const xMid = - 0.5 * (geometry.boundingBox!.max.x - geometry.boundingBox!.min.x);

        geometry.translate(xMid, 0, 0);

        // make shape ( N.B. edge view not visible )

        // const text = new THREE.Mesh(geometry, matLite);
        // text.position.z = - 150;
        // output.meshes.push(text);

        // make line shape ( N.B. edge view remains visible )

        const holeShapes = [];

        for (let i = 0; i < shapes.length; i++) {

            const shape = shapes[i];

            if (shape.holes && shape.holes.length > 0) {

                for (let j = 0; j < shape.holes.length; j++) {

                    const hole = shape.holes[j];
                    holeShapes.push(hole);

                }

            }

        }

        shapes.push.apply(shapes, holeShapes);

        const lineText = new THREE.Object3D();

        for (let i = 0; i < shapes.length; i++) {

            const shape = shapes[i];

            const points = shape.getPoints();
            const geometry = new THREE.BufferGeometry().setFromPoints(points);

            geometry.translate(xMid, 0, 0);

            const lineMesh = new THREE.Line(geometry, matDark);
            lineText.add(lineMesh);

        }

        output.objects.push(lineText);

        return output;

    }
    
    let loaderResp = await loader.loadAsync('api/GetJsonResource/helvetiker_regular.typeface.json'); //end load function
    
    let shapeData = onFontLoad(loaderResp);

    return shapeData;
}

export default GenerateShapesFromText;