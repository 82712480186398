import { useEffect, useRef } from "react";
import { IBarData, IBarTheme, IGraphMargin } from "./ChartInterfaces";

import * as d3 from 'd3';
import EventBus from "../../services/EventBus";
import React from "react";
import { useChartDetailsContext } from "../../contexts/chartDetailsContext/ChartDetailsProvider";

export interface IHorizotalBarProps {
    data: IBarData[],
    xAxisTitle: string,
    chartTitle: string | null | undefined,
    theme: IBarTheme | null,
    direction: 'Left' | 'Right',
    uuid: string
}

/* Similar to a divergent bar chart, but there are 2 X-Axis back to back. 
    This allows data with the same Y axis to be shown against 2 different X Axis Values ( eg car/bike over distance or Frontend/Backend over years of experience) */
/* The resultant chart is 2 barcharts back to back inverted, showing xAxis1 towards the left, and xAxis 2 towards the right */

const HorizontalBar = (props: IHorizotalBarProps) => {

    const xMaxValue = 15;

    const chartWrapper = useRef<HTMLDivElement | null>(null);

    const svg = useRef<d3.Selection<SVGGElement, unknown, null, undefined>>();

    let graphMargin: IGraphMargin = {
        top: 20,
        right: 100,
        bottom: 40,
        left: 100
    }

    useEffect(() => {

        if (doesChartExist(chartWrapper) === false) {
            initChart();
        }

    }, [chartWrapper])

    const chartDetailsContext = useChartDetailsContext();

    useEffect(() => {
        // reset bar colors if chart exists and focus has changed:
        if (doesChartExist(chartWrapper) === true) {

            let chartInFocusId = chartDetailsContext.getData()?.chartInFocusId;

            if (props.uuid !== chartInFocusId) {
                d3.select(chartWrapper.current)
                    .select("svg").select(".bar-group")
                    .selectAll("rect")
                    .attr("fill", props.theme?.PrimaryAxisColors[0] ?? "")
            }

        }
    }, [chartDetailsContext])


    /* Redraw on resize: */
    const initChart = () => {

        let wrapperWidth = chartWrapper.current!.offsetWidth;
        let wrapperHeight = chartWrapper.current!.offsetHeight;

        generateChart(props.data, props.direction, wrapperWidth, wrapperHeight, graphMargin, props.theme);

    }

    window.addEventListener("resize", () => {
        if (doesChartExist(chartWrapper) === true) {
            initChart();
        }
    });

    const generateChart = (
        data: IBarData[],
        direction: 'Left' | 'Right',
        chartWidth: number = 200,
        chartHeight: number = 400,
        margins: IGraphMargin,
        theme: IBarTheme | null
    ) => {

        // get colors:
        let mainBarColor = theme?.PrimaryAxisColors[0] ?? "#fff";
        let selectedColor = theme?.SecondaryAxisColors[0] ?? "#fff";
        let hoverColor = theme?.HighlightColors[0] ?? "#fff";

        // set the dimensions and margins of the graph
        let chartMargin: IGraphMargin = {
            top: margins.top,
            right: direction === 'Right' ? margins.right : 5,
            bottom: margins.bottom + 10,
            left: direction === 'Left' ? margins.left : 5
        },

            width = chartWidth - chartMargin.left - chartMargin.right,
            height = chartHeight - chartMargin.top - chartMargin.bottom;

        // Create SVG if not already drawn:
        if (!svg.current) {
            svg.current = d3.select(chartWrapper.current).append("svg")                
                .attr('width', width + chartMargin.left + chartMargin.right)
                .attr('height', height + chartMargin.top + chartMargin.bottom)
                .classed(`chart-svg-${props.uuid}`, true)
                .append("g")
                .attr("transform",
                    "translate(" + chartMargin.left + "," + chartMargin.top + ")");
        } else {
            d3.select(`.chart-svg-${props.uuid}`)
                .attr('width', width + chartMargin.left + chartMargin.right)
                .attr('height', height + chartMargin.top + chartMargin.bottom)            
        }

        // Add X axis
        var x = d3.scaleLinear()
            .domain(getXDomain(direction, xMaxValue))
            .range([0, width]);

        let xAxisGenerator = d3.axisBottom(x);

        let xAxis = svg.current!.selectAll(".bar-x-axis");

        if (xAxis.empty()) {
            svg.current!.append('g')
                .attr("transform", "translate(0," + height + ")")
                .classed("bar-x-axis", true)
                .call(xAxisGenerator)
                
        }else{
            xAxis
                // .attr("transform", "translate(0," + height + ")")
                .call(xAxisGenerator as any)
        }

        // Y axis generator:
        var y = d3.scaleBand()
            .range([0, height])
            .domain(data.map(function (d) { return d.name; }))
            .padding(.1);

        // draw Y Axis:
        let showYAxis = false;
        if (showYAxis) {
            let yAxis = d3.axisRight(y)
                .tickValues([]);

            svg.current.append("g")
                .call(yAxis);
        }

        let rects: d3.Selection<d3.BaseType, unknown, SVGGElement, unknown> =
            getOrCreate(
                svg.current,
                'rect.chart-interactive-element',
                () => svg.current!.append("g").classed("bar-group", true).selectAll("rect.chart-interactive-element")
            )!;
        
        let newRects = rects
            .data(data)
            .enter()
            .append("rect")
            .attr("y", function (d: IBarData) { return y(d.name) } as any)
            .attr("height", y.bandwidth())
            .attr("fill", mainBarColor)
            .attr("class", "chart-interactive-element")
            // .attr('filter', 'url(#dropshadow)')
            .on("click", function (data) {

                // reset all other nodes:
                let rects = svg.current!.select(".bar-group").selectAll("rect");

                if (d3.select(this).attr("fill") === selectedColor) {
                    // reset color and reset data:
                    d3.select(this).attr("fill", mainBarColor);
                    // props.updateDetailsCallback({});
                } else {

                    // Dispatch to all other charts that this has focus:
                    EventBus.dispatch("ChartFocusChange", { chartInFocusId: props.uuid, barData: null });
                    rects.attr("fill", mainBarColor);

                    var node = d3.select(this) as any;
                    var nodeData: IBarData = node._groups[0][0].__data__;

                    if (typeof nodeData !== 'undefined' || nodeData !== null) {

                        EventBus.dispatch("ChartDetailsChanged", { chartInFocusId: props.uuid, barData: nodeData });

                        // props.updateDetailsCallback(nodeData);
                        d3.select(this).attr("fill", selectedColor);
                    }
                }
            })
            .on("mouseenter", function (node) {
                if (d3.select(this).attr("fill") !== selectedColor) {
                    d3.select(this).attr("fill", hoverColor);
                }
            })
            .on("mouseleave", function (node) {
                if (d3.select(this).attr("fill") !== selectedColor) {
                    d3.select(this).attr("fill", mainBarColor);
                }
            });

        rects.exit().remove();

        // Bar Labels:
        let labels = getOrCreate(
            svg.current,
            'text.bar-label',
            () => svg.current!.append("g").attr("class", "bar-labels").selectAll("text.bar-label"))!;

        let newLabels = labels
            .data(data)
            .enter()
            .append("text")
            .attr("class", function (d, i, e) { return `bar-label label-${i}` })
            .attr("fill", "#fff")
            .attr("font-size", "0.75em")
            .attr("y", function (d) { return (y(d.name) as any) + 10; })
            .attr("dy", ".5em")
            .text(function (d) { return d.name; })

        newLabels.exit().remove();
        
        // Direction specific properties:
        // Notes on refactor - run init bar for all new rects, and refresh bar for current rects that were not removed.
        // Currently refresh only works if all bars remain the same or all change.
        
        let isNewData = newRects.empty() === false;

        let rectsToMove = !isNewData? rects : newRects as any;        
        let textToMove = newLabels.empty() ? labels : newLabels as any;
        
        if (direction === 'Right') {
            rectsToMove.transition()
                .duration(1000)
                .attr("x", x(0))
                .attr("width", function (d: IBarData) { return x(d.value) } as any)
                .delay((d: any, i:any) => i * 100);

            newLabels.attr("x", x(xMaxValue));

            textToMove
                .transition()
                .duration(1000)
                .attr("x", (function (data:any, i:number, nodes:any) {
                    return x(data.value) + 5;
                })
                )
                .delay((d:any, i:number) => i * 100);
        }
        if (direction === 'Left') {

            rectsToMove
                .attr("width", function (d: IBarData) { return x(d.value) + x(0); } as any);

                if(isNewData){
                    rectsToMove.attr("x", x(0));
                }

            rectsToMove.transition()
                .duration(1000)
                .attr("x", function (d: any) { return x(d.value) as any })
                .delay((d: any, i: number) => i * 100);

                textToMove
                .transition()
                .duration(1000)
                .attr("x", (function (this: any, data:any, i:number, nodes:any) {
                    
                    let bbox = this.getBBox();
                    let nodeWidth = bbox?.width ?? nodes[i].clientWidth;

                    let labelX = 0;
                    labelX = x(data.value) - (nodeWidth + 5 + (nodeWidth * 0.25));

                    return labelX;

                })
                )
                .delay((d:any, i:number) => i * 100);
        }

        // Annotations:
        //createAnnotations(svg.current!, chartWidth, chartHeight, props.chartTitle, props.xAxisTitle, margin, direction);
    }

    return (
        <>
            <div ref={chartWrapper} className="svg-container" style={{ width: '100%', height: '100%' }}>

            </div>
        </>
    )

}


const getOrCreate = (container: d3.Selection<SVGGElement, unknown, null, undefined>, className: string, createDelegate: () => void) => {
    let selectedElement = container.selectAll(className);

    if (selectedElement.empty()) {
        return createDelegate();
    }

    return selectedElement;
}

const getXAxis = (axisBottom: any) => {

    let xAxis = (g: d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>) => g
        .append("g").classed("bar-x-axis", true)
        // .attr("transform", "translate(0," + height + ")")
        .call(axisBottom)
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("font-size", "1.2em")
        .attr("transform", "translate(2,5)");

}

const doesChartExist = (chartWrapper: React.MutableRefObject<HTMLDivElement | null>) => {
    if (chartWrapper.current != null) {
        if (d3.select(chartWrapper.current).select('g').empty()) {
            return false
        } else {
            return true;
        }
    }
    return false;
}

const createAnnotations = (
    svg: d3.Selection<SVGGElement, unknown, null, undefined>,
    chartWidth: number,
    chartHeight: number,
    chartTitle: string | null | undefined = null,
    xAxisTitle: string,
    chartMargin: IGraphMargin,
    direction: 'Left' | 'Right'
) => {

    // x axis title:
    let xTitlePostion = {
        x: (chartWidth / 2) - (direction == 'Left' ? chartMargin.left : chartMargin.right),
        y: chartHeight - (chartMargin.bottom / 1.8)
    }

    // XAxis
    let textGroup = svg.append("g");

    textGroup.append('text')
        .attr('x', xTitlePostion.x)
        .attr('y', xTitlePostion.y)
        .attr("class", "label")
        .attr("fill", "#fff")
        .attr("font-size", "0.75em")
        .text(xAxisTitle)
}

const getXDomain = (direction: 'Left' | 'Right', maxValue: number) => {
    if (direction == 'Right') {
        return [0, maxValue]
    }

    if (direction == 'Left') {
        return [maxValue, 0]
    }

    return [0, 0];
}

export default HorizontalBar;