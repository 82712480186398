import { Action } from "@reduxjs/toolkit";
import React, { SetStateAction, useEffect, useReducer, useState } from "react";
import { IBarData } from "../../features/charts/ChartInterfaces";
import EventBus, { IBarChartFocusEvent } from "../../services/EventBus";

interface IChartDetails {
    getData: () => IBarChartFocusEvent,
    setData: (data:IBarChartFocusEvent) => void
}

const ChartDetailsContext = React.createContext<IChartDetails>(null!);

export function useChartDetailsContext(){
    return React.useContext(ChartDetailsContext);
}

function stateReducer(state: IBarChartFocusEvent, action: { type: string, payload: IBarChartFocusEvent | null }) {
    switch (action.type) {
        case 'update':
            return action.payload            
        default:
            break;
    }
}

export default function ChartDetailsProvider({ children }: { children: React.ReactNode }){

    const [state, dispatch] = useReducer(stateReducer, null!);

    let  getData = () => state;
    let  setData = (data:IBarChartFocusEvent | null) => dispatch({type:'update', payload: data});

    useEffect(()=>{
        //Events:
        EventBus.on("ChartDetailsChanged", (data:IBarChartFocusEvent) =>{            
            setData(data);
        })
    },[])
    
    return (
        <ChartDetailsContext.Provider 
            value={{ getData, setData }}
        > 
            { children } 
        </ChartDetailsContext.Provider>);
}