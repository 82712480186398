import axios from "axios";
import SharedAxisDivergentChart from "../charts/SharedAxisDivergentChart/SharedAxisDivergentChart";

const VerticalDetails = () => {

    return (
        <>
            <div className="container vertical-details-wrapper mt-5">
                
                <div className="row">

                    <div className="col-sm-12 section-box">

                        <div className="row">

                            <div className="col-sm-6">
                                <h3 className="section-title"><span className="company-first">MATERIAL</span><span className="company-second">METHODS</span></h3>
                            </div>

                            <div className="col-sm-12 section-content">
                                <p>Software Design &amp; Development Consultancy</p>
                                <p>Specialising in Full Stack web development and Windows Software development</p>
                            </div>

                        </div>

                    </div>

                </div>

                {/* Company Info */}


                {/* About Info */}

                <div className="row pt-4">

                    <div className="col-sm-12 section-box ">

                        <div className="row">
                            <div className="col-sm-6">
                                <h3 className="section-title"><span className="company-first">SHAUN</span><span className="company-second">FORDE</span></h3>
                            </div>

                            <div className="col-sm-12 section-content">
                                <p>Director &amp; primary consultant</p>

                                <p> Proactive, dedicated and innovative, with an outstanding track record in software development and data visualisation. <br />
                                    Director and Primary Consultant, drawing on 14 years of leading projects in Banking, Education, Legal and other industries.</p>
                            </div>
                        </div>


                    </div>

                </div>

                {/* Experience Section */}

                <div className="row pt-4 mb-5">

                    <div className="col-sm-12 ">

                        <SharedAxisDivergentChart />

                    </div>

                </div>

                <div className="row mb-5">
                    <div className="col-sm-12 section-box">

                        <div className="col-sm-6">
                            <h3 className="section-title"><span className="company-first">CONTACT</span><span className="company-second">ME</span></h3>
                        </div>

                        <div className="col-sm-12 section-content pt-1">
                            <p>For all enquiries, email <a className="" href="mailto:shaunforde@material-methods.com">shaunforde@material-methods.com</a> </p>
                        </div>

                    </div>
                </div>

            </div>


        </>
    )
}

export default VerticalDetails;