import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import * as THREE from 'three';
import { initialiseScene, moveToCameraStage, moveToRandomPosition } from "./ThreeJS/GatewayScene";
import { IThreeJSRequirements } from "./ThreeJS/IThreeJSRequirements";

const ThreeJSHeader = () => {

    THREE.Cache.enabled = true;

    /* Graph state */
    const wrapper = useRef<HTMLDivElement | null>(null);
    const isCanvasLoaded = () => wrapper.current !== null && wrapper.current.children.length > 0;

    useEffect(() => {
        if (!isCanvasLoaded()) {
            if (sceneRequirements === null) {
                setSceneRequirements(
                    {
                        camera: new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 0.1, 2000),
                        wrapperElement: wrapper.current
                    }
                );
            }
        }
    }, [wrapper])


    const [sceneRequirements, setSceneRequirements] = useState<IThreeJSRequirements | null>(null)

    const [scene, setScene] = useState<THREE.Scene | null>(null);

    useEffect(() => {

        const setTheScene = async (sceneRequirements: IThreeJSRequirements) =>{
            var scene = await initialiseScene(sceneRequirements);

            setScene(scene);
        }

        if (sceneRequirements !== null && scene === null) {
            // Scene is set in state, so initialise everything else:
            setTheScene(sceneRequirements);
        }
    
    }, [sceneRequirements])
    
    useEffect(() => {
        if(scene !== null){
            // Scene will be rendered at this point: add custom events objects here:

        }
    }, [scene]) 

    
    /* 3D Manipulation */
    const location = useLocation();

    const changeScene = (type: string, event: any = null) => {
        if(sceneRequirements !== null){
            moveToCameraStage(sceneRequirements.camera, type);
        }
    }

    useEffect(()=>{
        // deduce stage from url location: TODO.
        // console.log(location);

        // if(location.pathname == '/'){
        //     changeScene('stage1');
        // }

        // if(location.pathname == '/About'){
        //     changeScene('stage2');
        // }

        // if(location.pathname == '/Contact'){
        //     changeScene('stage3');
        // }

    }, [location])

    return (
        <div style={{ position: 'relative', width:'100%', height:'100%' }}>      

            <div style={{ width:'100%', height:'100%', overflow:'hidden' }} ref={wrapper}>

            </div>

        </div>
    )

}

export default ThreeJSHeader;

interface IDebugAreaProps {
    cameraX: number,
    cameraY: number,
    cameraZ: number,
}