import { miniSerializeError } from '@reduxjs/toolkit';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { IControlledMixer } from '../../../../assets/threejs/interfaces/animationInterfaces';

class GatewayBeamSequence {

    /**
     * Beam animations for gateway scene
     */
    constructor() {

    }

    init(scene: THREE.Scene, initialVector:Vector3 ) {

        // Create the orb:
        let orb = createOrb();

        orb.position.set(
            initialVector.x,
            initialVector.y,
            initialVector.z,
        )

        return orb;
    }
    
    generateOrbAnimation(orb:THREE.Object3D, clipName:string){

        let currentPosition = orb.position;

        let initialVector:Vector3 = currentPosition;

        let midVector:Vector3 = new Vector3(4,1,0);

        let finalVector:Vector3 = currentPosition;

        const positions = [
            initialVector.toArray(),
            midVector.toArray(),
            finalVector.toArray()
        ];

        const positionKF = new THREE.VectorKeyframeTrack( '.position', [ 0, 1, 2 ], positions.flat(), THREE.InterpolateSmooth );

        const clip = new THREE.AnimationClip('Action', 3, [positionKF]);
        clip.name = clipName;

        let controlledMixer = {
            mixer: new THREE.AnimationMixer(orb),
            name: `Mixer_${clipName}`
        } as IControlledMixer;
        
        const clipAction = controlledMixer.mixer.clipAction(clip);
        
        clipAction.play();

        return controlledMixer;
    }


}

const createOrb = () => {
    const geometry = new THREE.SphereGeometry(0.1, 10, 16);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const sphere = new THREE.Mesh(geometry, material);

    return sphere
}

export default GatewayBeamSequence;