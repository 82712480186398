import { useEffect, useRef, useState } from "react";
import { useChartDetailsContext } from "../../../contexts/chartDetailsContext/ChartDetailsProvider";
import { IBarData } from "../ChartInterfaces";

import styles from './ChartDetailsSections.module.css';

interface IChartDetailsProps {
    chartId?:string
}

const ChartDetails = (props:IChartDetailsProps) => {

    const defaultDetailsText = "Click on a bar above for more information";

    const detailsContent = useRef<any>(processDetailsHtml(null, defaultDetailsText));

    const [chartDetails, setChartDetails] = useState<any>(null);

    /* Transitions */
    const [transitionStage, setTransistionStage] = useState("fadeIn");

    const processAnimationEnd = () => {
        if (transitionStage === "fadeOut") {
            detailsContent.current = processDetailsHtml(chartDetails, defaultDetailsText);
            setTransistionStage("fadeIn");
        }
    }

    const chartDetailsContext = useChartDetailsContext();

    useEffect(()=> {

            let chartUpdateData = chartDetailsContext?.getData();

            if(typeof props.chartId !== 'undefined'){
                if(props.chartId === chartUpdateData?.chartInFocusId){
                    setChartDetails(chartUpdateData?.barData);
                    setTransistionStage("fadeOut");
                }
            }else {
                setChartDetails(chartUpdateData?.barData);
                setTransistionStage("fadeOut");
            }
        
        },[chartDetailsContext])

    return (

        <>
            <div
                className={`${transitionStage} fade-wrapper`}
                onAnimationEnd={() => {
                    processAnimationEnd()
                }}
            >
                {detailsContent.current}
            </div>

        </>
    )

};


const processDetailsHtml = (data: IBarData | null, defaultText: string) => {
    return (
        <>
            <div className="row">

                <div className="col-sm-12">
                    <span className={styles.chartDetailsTitle}>

                        {
                            processDetailsTitle(data)
                        }

                    </span>
                </div>

                <div className="col-sm-12">
                    <span className=''>
                        {data?.additionalData}
                    </span>
                </div>

            </div>
        </>
    )
}


const processDetailsTitle = (data: IBarData | null) => {

    return (
        <>
            {data?.name}  {data?.value ? `|` : null} <span className={styles.chartDetailsValue}>{data?.value} {data?.valueType}</span>
        </>
    )

}

export default ChartDetails;