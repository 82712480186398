
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';

import { IThreeJSRequirements } from './IThreeJSRequirements';

// controls:
import { OrbitControls } from '../../../assets/threejs/controls/OrbitControls';

//Gui:
import { GUI } from '../../../assets/threejs/libs/lil-gui.module.min.js';
import Stats from '../../../assets/threejs/libs/stats.module.js';

// loaders and post processing:
import { GLTFLoader } from '../../../assets/threejs/loaders/GLTFLoader.js';
import { EffectComposer } from '../../../assets/threejs/postProcessing/EffectComposer.js';
import { RenderPass } from '../../../assets/threejs/postProcessing/RenderPass.js';
import { UnrealBloomPass } from '../../../assets/threejs/postProcessing/UnrealBloomPass.js';
import { Camera, Vector3 } from 'three';
import axios from 'axios';

// Variables:
import { GatewaySceneVariables } from './GatewaySceneUtils/GatewaySceneVariables';
import GenerateShapesFromText from './modelLoaders/textGeometryLoader';
import GatewayBeamSequence from './GatewaySceneUtils/GatewayBeamSequence';
import { IControlledMixer } from '../../../assets/threejs/interfaces/animationInterfaces';
import _default from 'react-redux/es/components/connect';
import ObjectHelper from '../../../helpers/objectHelper';
import ObjectCacheService from '../../../services/ObjectCache';
import { debug } from 'console';
import { mode } from 'd3';
import ModelAssets from '../../../data/3dModelAssetNames';

export const initialiseScene = async (sceneRequirements: IThreeJSRequirements) => {

    // Setup variables:
    const cameraVars = GatewaySceneVariables.cameraStages;

    // End Setup Variables

    const scene = new THREE.Scene();

    const renderer = new THREE.WebGLRenderer();

    let clock = new THREE.Clock();

    renderer.setSize(window.innerWidth, window.innerHeight);

    // Add renderer element (<canvas>) to wrapper element:
    sceneRequirements.wrapperElement!.appendChild(renderer.domElement);
    // Force camera to position:

    let initalCameraParams = {
        cameraX: cameraVars[0].cameraX,
        cameraY: cameraVars[0].cameraY,
        cameraZ: cameraVars[0].cameraZ
    }

    sceneRequirements.camera.position.set(
        initalCameraParams.cameraX,
        initalCameraParams.cameraY,
        initalCameraParams.cameraZ);

    sceneRequirements.camera.lookAt(new THREE.Vector3(0, 0, 0));

    // sceneRequirements.camera.zoom = 5;

    const setOrbitControls = () => {
        // Added gateway assets an lighting:
        const controls = new OrbitControls(sceneRequirements.camera, renderer.domElement);
        controls.maxPolarAngle = Math.PI * 0.5;
        controls.minDistance = 1;
        controls.maxDistance = 100;

        // Stage Change Events:
        controls.addEventListener('change', (event) => {
            // console.log(controls.object.position);
        });

    }

    // Window Events:
    const onWindowResize = () => {
        sceneRequirements.camera.aspect = window.innerWidth / window.innerHeight;
        sceneRequirements.camera.updateProjectionMatrix();

        sceneRequirements.camera.position.set(
            initalCameraParams.cameraX,
            initalCameraParams.cameraY,
            initalCameraParams.cameraZ);

        // sceneRequirements.camera.zoom = 5;

        sceneRequirements.camera.lookAt(new THREE.Vector3(0, 0, 0));

        renderer.setSize(window.innerWidth, window.innerHeight);
    }

    window.addEventListener('resize', onWindowResize, false);

    // DEBUG: Grid Helper:
    const size = 10;
    const divisions = 10;

    const gridHelper = new THREE.GridHelper(size, divisions);
    //scene.add(gridHelper);

    // Activate orbit controls
    if (false) {
        setOrbitControls();
    }
    // Graphics Settings:
    const params = {
        exposure: 1,
        bloomStrength: 2.5,
        bloomThreshold: 0,
        bloomRadius: 0
    };

    scene.add(new THREE.AmbientLight(0x404040));

    const pointLight = new THREE.PointLight(0xffffff, 1);
    sceneRequirements.camera.add(pointLight);

    const renderScene = new RenderPass(scene, sceneRequirements.camera);

    const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
    bloomPass.threshold = params.bloomThreshold;
    bloomPass.strength = params.bloomStrength;
    bloomPass.radius = params.bloomRadius;

    let composer = new EffectComposer(renderer);
    composer.addPass(renderScene);
    composer.addPass(bloomPass);

    // let stats: any = Stats();
    // sceneRequirements.wrapperElement!.appendChild(stats.dom);

    // Set loading text:

    let showLoadingAssets = true;

    interface IAnimationCommand {
        isActive: boolean,
        id: string,
        animate: (delta: number) => void
    }

    let simpleAnimations: IAnimationCommand[] = [];

    let animationModifers: ((commandList: IAnimationCommand[]) => void)[] = [];

    if (showLoadingAssets) {
        let loadingScreenAssets = await GenerateShapesFromText("MATERIAL METHODS", 0.25);

        // loadingScreenAssets.meshes.forEach(asset => {
        //     scene.add(asset);
        // })

        loadingScreenAssets.objects.forEach(asset => {

            asset.name = 'OBJECT_LOADINGTEXT';

            scene.add(asset);
            // center rotation towards camera:
            asset.rotateY(0.785);
            simpleAnimations.push(
                {
                    id: 'loading',
                    isActive: true,
                    animate: (delta: number) => {

                        let frontFacingRad = (Math.PI / 4);

                        if (asset.rotation.y > frontFacingRad) {
                            asset.rotateY(delta * 0.5);
                        } else {
                            asset.rotateY((delta * 4));
                        }

                        // console.log(THREE.MathUtils.radToDeg(asset.rotation.y));
                    }
                });

            simpleAnimations.push(
                {
                    id: 'loading_fadeOut',
                    isActive: false,
                    animate: (delta: number) => {

                        // animate mesh opacity
                        if (scene.getObjectByName('OBJECT_LOADINGTEXT')) {
                            scene.remove(asset);
                        }

                    }
                });
        })

    }

    // Get 3d resources:
    var controlledMixers: IControlledMixer[] = [];

    // register scene activity delegate:
    const initSceneActivity = () => {

        const beamSequence = new GatewayBeamSequence();

        let initialVector: Vector3 = new Vector3(-4.5, 1, 0)
        let orb = beamSequence.init(scene, initialVector);

        scene.add(orb);

        let orbAnimationMixer = beamSequence.generateOrbAnimation(orb, "OrbSequence_Initial");

        controlledMixers.push(orbAnimationMixer);
    }

    const startNewOrbAnimation = (mixers: IControlledMixer[]) => {

        // remove previous mixer:        
        let orbMixer = mixers.find(x => x.name === 'Mixer_OrbSequence_Initial')

        orbMixer?.mixer.stopAllAction();

    }

    const initialiseGateway = (gltf: ISceneObject) => {

        let model = gltf.objectData.scene;

        model.position.y = gltf.yPosition;
        model.position.x = gltf.xPosition;

        let mixer = new THREE.AnimationMixer(model);
        const clips = gltf.objectData.animations;

        clips.map((x: any) => {
            x.name = `${gltf.name}_${x.name}`;
            x.uuid = `${gltf.name}_${x.uuid}`;
            return x;
        })

        for (let i = 0; i < clips.length; i++) {
            mixer.clipAction(clips[i].optimize()).play();
        }

        // return animation mixer:
        return {
            model: model,
            mixer: {
                name: `Mixer_${gltf.name}_rotatation`,
                mixer: mixer
            } as IControlledMixer
        }
    }

    get3DResources().then(x => {

        let gatewayAnimationMixers: { model: any, mixer: IControlledMixer }[] = []

        x.forEach(resource => {
            if (resource.type === 'GLTF') {
                gatewayAnimationMixers.push(initialiseGateway(resource));
            }
        });

        // Play all gateways and trigger main sequence:
        // let loading text show for a bit
        setTimeout(() => {

            // add animation command to be queued - remove loading:
            animationModifers.push((commandList: IAnimationCommand[]) => {

                // disable loading animation
                let animToCut = commandList.findIndex(x => x.id === 'loading');
                commandList[animToCut].isActive = false;

                // trigger loading text removal:
                let animToTrigger = commandList.findIndex(x => x.id === 'loading_fadeOut')
                commandList[animToTrigger].isActive = true;
            })

            gatewayAnimationMixers.forEach(gatewaySequence => {
                // register animation mixers:
                scene.add(gatewaySequence.model);
                controlledMixers.push(gatewaySequence.mixer);
            })

            // Trigger other sequences for gateway scene:
            initSceneActivity();

        }, 1000);

    });

    // animate:
    animate((time) => {

        const delta = clock.getDelta();

        // run animation modifiers
        animationModifers.forEach(modifier => modifier(simpleAnimations));

        // run any registered animations:
        simpleAnimations.filter(x => x.isActive === true).forEach(anim => { anim.animate(delta) });

        controlledMixers.forEach(mixer => {
            mixer.mixer.update(delta);
        })

        // stats.update();

        composer.render();

        TWEEN.update(time);

        // remove all animation modifiers:
        animationModifers = [];

    })

    // Window Events:

    // return the scene back:
    return scene;
}

const animate = (callback: (time: any) => void) => {

    function loop(time: any) {
        callback(time);
        requestAnimationFrame(loop);
    }

    // Initialise animation frames, will continued to call loop
    requestAnimationFrame(loop);
}

/* Manipulation */
export const moveToRandomPosition = (camera: Camera) => {

}

export const moveToCameraStage = (camera: Camera, stageName: string) => {
    if (stageName !== null) {

        var cameraStage = GatewaySceneVariables.cameraStages.find(x => x.stageName === stageName);

        if (typeof cameraStage !== 'undefined') {

            var currentPos = camera.position;

            new TWEEN.Tween(currentPos)
                .to({
                    x: cameraStage.cameraX,
                    y: cameraStage.cameraY,
                    z: cameraStage.cameraZ
                })
                .easing(TWEEN.Easing.Quadratic.InOut)
                .onUpdate(() => {
                    console.log("tweening");
                    camera.position.set(currentPos.x, currentPos.y, currentPos.z)
                })
                .start();
        }

    }
}

const setGui = () => {

    //#region GUI
    // const gui = new GUI() as any;

    // gui.add(initalCameraParams, 'cameraX', -5,20,0.02).onChange((value:any) => {
    //     sceneRequirements.camera.position.setX(value);
    // })


    // gui.add(params, 'exposure', 0.1, 2).onChange(function (value: any) {

    //     renderer.toneMappingExposure = Math.pow(value, 4.0);

    // });

    // gui.add(params, 'bloomThreshold', 0.0, 1.0).onChange(function (value: any) {

    //     bloomPass.threshold = Number(value);

    // });

    // gui.add(params, 'bloomStrength', 0.0, 3.0).onChange(function (value: any) {

    //     bloomPass.strength = Number(value);

    // });

    // gui.add(params, 'bloomRadius', 0.0, 1.0).step(0.01).onChange(function (value: any) {

    //     bloomPass.radius = Number(value);

    // });

}

/* Resources */
async function get3DResources(): Promise<ISceneObject[]> {

    let gatewayUri = "api/Get3dResources";

    var loader = new GLTFLoader();
    
    const getModel = async (modelFileName: string) => {
        let model = null;

        // check cache
        let cachedModel = await ObjectCacheService.getResourceAndCache('3DAssets', `${gatewayUri}/${modelFileName}`);

        if (cachedModel) {

            return await loader.cachedLoad(cachedModel, gatewayUri, (gltf: any) => { return gltf.scene; },
                (error: any) => {
                })
        } else {
            let modelResolver: (value: unknown) => void;

            let modelWaiter = new Promise((resolve, reject) => {
                modelResolver = resolve;
            });

            loader.load(gatewayUri, function (gltf: any) {
                model = gltf.scene
                modelResolver(gltf);
            });

            model = await modelWaiter;

            return model;
        }

    }

    let g1 = {
        name: 'gateway1',
        objectData: await getModel(ModelAssets.Gateway.fileName),
        type: 'GLTF',
        xPosition: -4.5,
        yPosition: 0.9
    } as ISceneObject;

    let g2 = {
        name: 'gateway2',
        objectData: await getModel(ModelAssets.Gateway.fileName),
        type: 'GLTF',
        xPosition: 0,
        yPosition: 0.9
    } as ISceneObject;

    let g3 = {
        name: 'gateway3',
        objectData: await getModel(ModelAssets.Gateway.fileName),
        type: 'GLTF',
        xPosition: 4.5,
        yPosition: 0.9
    } as ISceneObject;

    return [
        g1, g2, g3
    ]

}

interface ISceneObject {
    name: string,
    objectData: any,
    type: 'GLTF' | object,
    xPosition: number,
    yPosition: number
}