import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import { DetailsSection } from './features/details-section/detailsSection';
import { BrowserRouter } from 'react-router-dom';
import Footer from './features/footer/footer';
import HeaderCanvas from './features/header/headerCanvas';
import ThreeJSHeader from './features/header/ThreeJSHeader';
import VerticalDetails from './features/vertical-details/VerticalDetails';

function App() {
  return (
    <BrowserRouter>
      <div className="App">

        <div className="head-section">

          <ThreeJSHeader />

          <div className="main-header-section">

            <div className="container-fluid">
            
              {/* <div className="row">
                <div className="col-sm-12 d-flex justify-content-start">
                  <span className="main-header-section__title">Material Methods</span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 d-flex justify-content-start">
                  <span className="main-header-section__subtitle">software development | data visualisation</span>
                </div>
              </div> */}

            </div>

          </div>
        </div>

        {/* <div className="detail-section">
          <DetailsSection />
        </div> */}

        <div className="vertical-details">

          <VerticalDetails />

        </div>

        <Footer />

      </div>
    </BrowserRouter>

  );
}

export default App;
